import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import { useIntl } from "gatsby-plugin-intl"
import AboutRu from "../components/about.ru"
import AboutRo from "../components/about.ro"

const AboutPage = (): JSX.Element => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "about.title" })}
        description={intl.formatMessage({ id: "about.whoAnswer" })}
      />
      <Header
        title={intl.formatMessage({ id: "about.title" })}
        subtitle="Media RADAR"
      />
      {intl.locale === "ru" ? <AboutRu /> : <AboutRo />}
    </Layout>
  )
}

export default AboutPage
