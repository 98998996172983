import React from "react"
import { Row, Container, Col } from "react-bootstrap"
import { Link } from "gatsby-plugin-intl"

const AboutRu = (): JSX.Element => (
  <div className="about-page">
    <Row className="bg-white py-5" noGutters>
      <Container className="pt-4 pb-5 px-lg-5">
        <div className="px-lg-5">
          <h3>
            <strong>Кто мы?</strong>
          </h3>
          <div className="text-block">
            <p className="mb-5 mt-4">
              Media Radar - это новый инструмент, с которым Независимый центр
              журналистики пытается помочь потребителям СМИ легче
              ориентироваться в информации, предоставляемой десятками
              надежных, менее надежных или проверенных новостных порталов
              каждый день.
              </p>
          </div>
          <h3 className="pt-4">
            <strong>Как?</strong>
          </h3>
          <div className="text-block">
            <p className="mb-5 mt-4">
              Суть мониторинга заключается в оценке и вынесении вердиктов о
              достоверности информационных порталов. Результаты будут включены
              в онлайн-базу данных, которая будет подключена к мобильному
              приложению Media Radar, пользователи которого будут
              проинформированы о достоверности этих сайтов. Информационные
              порталы будут оцениваться систематически, в соответствии с
              изменениями или инволюциями в сегменте онлайн-СМИ, а результаты
              будут публиковаться в верхней части наиболее достоверных
              источников информации в Интернете.
              </p>
            <p className="mb-5 mt-4">
              Платформа будет предоставлять данные о степени доверия к
              информационным порталам в Республике Молдова, которые считаются
              частью онлайн-СМИ, функционирующих на момент мониторинга и
              объявления результатов, которые отслеживались и оценивались с
                точки зрения критериев, установленных в{" "}
              <Link to="/metodologia/">методологии</Link> мониторинга.{" "}
            </p>
          </div>
        </div>
      </Container>
    </Row>
    <Row className="py-5" noGutters>
      <Container className="py-5">
        <div className="mb-5 mt-4">
          <h4> Почему?</h4>
        </div>
        <Row className="pb-4">
          <Col lg={4} sm={12} className="mb-lg-0 mb-5">
            <div className="green-point"></div>
            <h6>Достаточность данных идентификация порталов.</h6>
            <p className="paragraph">
              Аргумент - Чем больше данных публикуется, тем меньше подозрений
              в недобросовестности со стороны пользователя; открытые данные
              способствуют интерактивности (существенная особенность
              онлайн-СМИ) и в конечном итоге укрепляют доверие к
              информационным порталам.
              </p>
          </Col>
          <Col lg={4} sm={12} className="mb-lg-0 mb-5">
            <div className="green-point"></div>
            <h6>Качество контента СМИ</h6>
            <p className="paragraph">
              Аргумент - Информационные порталы должны предоставлять правдивую
              и честную информацию, в отличие от предосудительной практики
              дезинформации и манипулирования через псевдоинформационные
              порталы; Медиа-контент оценивается для определения его
              соответствия юридическим и этическим требованиям, предъявляемым
              к традиционному медиа-источнику.
              </p>
          </Col>
          <Col lg={4} sm={12} className="mb-lg-0 mb-5">
            <div className="green-point"></div>
            <h6>Степень доступности данных портала.</h6>
            <p className="paragraph">
              Аргумент - легкий доступ к запрошенной пользователем информации
              демонстрирует заботу портала об удобстве пользователя и времени.
              </p>
          </Col>
        </Row>
      </Container>
    </Row>
    <Row className="py-5 bg-white" noGutters>
      <Container className="py-5">
        <div className="mb-5">
          <h4 className="text-center">Принципы и ценности</h4>
          <h2>Поддерживая эту платформу, CJI будет возглавляться:</h2>
        </div>
        <Row className="pb-4">
          <Col lg={4} sm={12} className="mb-lg-0 mb-5">
            <div className="green-point"></div>
            <h6>Принцип независимости:</h6>
            <p className="paragraph">
              Авторы отчетов не зависят от каких-либо влияющих факторов и
              независимы друг от друга.
              </p>
          </Col>
          <Col lg={4} sm={12} className="mb-lg-0 mb-5">
            <div className="green-point"></div>
            <h6>Принцип прозрачности и методология:</h6>
            <p className="paragraph">
              Результаты мониторинга предаются гласности.
              </p>
          </Col>
          <Col lg={4} sm={12} className="mb-lg-0 mb-5">
            <div className="green-point"></div>
            <h6>Принцип объективности:</h6>
            <p className="paragraph">
              Результаты мониторинга отражают ситуацию по заранее
              установленным критериям и не зависят от воли авторов
              мониторинга.
              </p>
          </Col>
        </Row>
      </Container>
    </Row>
  </div>
)

export default AboutRu
