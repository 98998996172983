import React from "react"
import { Row, Container, Col } from "react-bootstrap"
import { Link } from "gatsby-plugin-intl"

const AboutRo = (): JSX.Element => (
  <div className="about-page">
    <Row className="bg-white py-5" noGutters>
      <Container className="pt-4 pb-5 px-lg-5">
        <div className="px-lg-5">
          <h3>
            <strong>Cine, ce?</strong>
          </h3>
          <div className="text-block">
            <p className="mb-5 mt-4">
              Media Radar este un nou instrument prin care Centrul pentru
              Jurnalism Independent și-a propus să ajute consumatorii de media
              să navigheze mai ușor prin informația oferită zilnic de zecile
              de portaluri informaționale credibile, mai puțin credibile sau
              deloc credibile.
              </p>
          </div>
          <h3 className="pt-4">
            <strong>Cum?</strong>
          </h3>
          <div className="text-block">
            <p className="mb-5 mt-4">
              Esența monitorizării constă în evaluarea și atribuirea
              verdictelor privind credibilitatea portalurilor informaționale.
              Rezultatele vor fi incluse într-o bază de date online care va fi
              conectată cu aplicația mobilă Media Radar, ai căror utilizatori
              vor fi informați despre credibilitatea site-urilor respective.
              Portalurile informaționale for fi evaluate sistematic, urmărind
              evoluțiile sau involuțiile de pe segmentul mass-media on-line,
              iar rezultatele vor fi publicate în topul celor mai credibile
              surse de informare online.
              </p>
            <p className="mb-5 mt-4">
              Platforma va oferi date privind gradul de credibilitate a
              portalurilor informaționale din RM considerate a face parte din
              mass-media on-line, funcționale la momentul efectuării
              monitorizării și anunțării rezultatelor, care au fost
                monitorizate și evaluate prin prisma unor criterii stabilite în{" "}
              <Link to="/metodologia/">metodologia</Link> de monitorizare.
              </p>
          </div>
        </div>
      </Container>
    </Row>
    <Row className="py-5" noGutters>
      <Container className="py-5">
        <div className="mb-5 mt-4">
          <h4> De ce evaluăm?</h4>
        </div>
        <Row className="pb-4">
          <Col lg={4} sm={12} className="mb-lg-0 mb-5">
            <div className="green-point"></div>
            <h6>Suficiența datelor de identificare a portalurilor.</h6>
            <p className="paragraph">
              Argument - Cu cît mai multe date sunt publicate, cu atît mai
              puține eventuale suspiciuni de rea-credință din partea
              utilizatorului; datele deschise facilitează interactivitatea
              (caracteristică esențială pentru media on-line) și, în
              definitiv, fortifică credibilitatea portalurilor informaționale.
              </p>
          </Col>
          <Col lg={4} sm={12} className="mb-lg-0 mb-5">
            <div className="green-point"></div>
            <h6>Calitatea conținuturilor mediatice.</h6>
            <p className="paragraph">
              Argument - Portalurile informaționale trebuie să ofere
              informații veridice și oneste, în contrapunere cu practicile
              condamnabile de dezinformare și de manipulare prin intermediul
              portalurilor pseudo-informaționale; Conținutul media este
              evaluat pentru a stabili corespunderea acestuia cu rigorile
              juridice și deontologice impuse unei surse media tradiționale.
              </p>
          </Col>
          <Col lg={4} sm={12} className="mb-lg-0 mb-5">
            <div className="green-point"></div>
            <h6>Gradul de accesibilitate la datele portalurilor.</h6>
            <p className="paragraph">
              Argument - Accesul facil la informația solicitată de utilizator
              demonstrează grija portalului pentru comoditatea și pentru
              timpul utilizatorului.
              </p>
          </Col>
        </Row>
      </Container>
    </Row>
    <Row className="py-5 bg-white" noGutters>
      <Container className="py-5">
        <div className="mb-5">
          <h4 className="text-center">Principii și valori</h4>
          <h2>La întreținerea acestei platforme, CJI se va conduce de:</h2>
        </div>
        <Row className="pb-4">
          <Col lg={4} sm={12} className="mb-lg-0 mb-5">
            <div className="green-point"></div>
            <h6>Principiul independenței:</h6>
            <p className="paragraph">
              Autorii rapoartelor sunt independenți de oricare factori de
              influență și independenți unii de alții.
              </p>
          </Col>
          <Col lg={4} sm={12} className="mb-lg-0 mb-5">
            <div className="green-point"></div>
            <h6>Principiul transparenței:</h6>
            <p className="paragraph">
              Rezultatele monitorizării sunt făcute publice
              </p>
          </Col>
          <Col lg={4} sm={12} className="mb-lg-0 mb-5">
            <div className="green-point"></div>
            <h6>Principiul obiectivității:</h6>
            <p className="paragraph">
              Rezultatele monitorizării reflectă situația conform criteriilor
              prestabilite și nu depind de voința autorilor monitorizării.
              </p>
          </Col>
        </Row>
      </Container>
    </Row>
  </div>
)

export default AboutRo
